import * as THREE from 'three';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';

export default class ThreeEvent {
  constructor(Box, movieBool) {
    this.Box = Box;
    this.Timer = null;
    this.TimerBool = movieBool;
    this.init();
  }
  init() {
    const Box = this.Box;
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    let per = 540;
    let width = Box.clientWidth;
    let ypos = -40;
    const height = Box.clientHeight;
    let cylinderZ = 0;
    let cylinderX = 0;
    if (width < 786) {
      per = 380;
    }
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);
    renderer.setClearColor(0x000000, 0);
    Box.appendChild(renderer.domElement);

    const scene = new THREE.Scene();

    // カメラを作成
    const camera = new THREE.PerspectiveCamera(20, width / height);
    if (width > 1440) {
      width = 1440;
      ypos = 0;
    }

    let cameraPosZ = (1440 / width) * per; //540;
    // camera.position.set(0, -40, cameraPosZ);
    camera.position.set(0, ypos, cameraPosZ);
    const wrap = new THREE.Group();

    const geometry = new THREE.CylinderGeometry(87, 87, 15, 50, 50, true);
    const colorTexture = new THREE.TextureLoader().load('/assets/images/logo_txt.png', () => {
      let event = new Event('TextureLoaded', { bubbles: true });
      document.dispatchEvent(event);
    });
    const material = new THREE.MeshBasicMaterial({
      map: colorTexture,
      side: THREE.FrontSide,
      depthWrite: false,
      transparent: true,
    });
    colorTexture.minFilter = THREE.LinearFilter;
    const cylinder = new THREE.Mesh(geometry, material);
    wrap.add(cylinder);

    cylinder.position.x = 0;
    cylinder.position.y = 0;
    cylinder.position.z = 0;
    if (window.matchMedia('(max-width: 768px)').matches) {
      cylinder.rotation.z = 0.3;
      cylinder.rotation.x = 0.3;
    } else {
    }

    const geometry2 = new THREE.CylinderGeometry(86.9, 86.9, 15, 50, 50, true);
    const colorTexture2 = new THREE.TextureLoader().load('/assets/images/logo_txt2.png', () => {
      let event = new Event('TextureLoaded', { bubbles: true });
      document.dispatchEvent(event);
    });

    const material2 = new THREE.MeshBasicMaterial({
      map: colorTexture2,
      side: THREE.BackSide,
      depthWrite: false,
      transparent: true,
    });
    colorTexture2.minFilter = THREE.LinearFilter;
    const cylinder2 = new THREE.Mesh(geometry2, material2);
    wrap.add(cylinder2);
    cylinder2.position.x = 0.05;
    cylinder2.position.y = -0.05;
    cylinder2.position.z = -0.05;
    if (window.matchMedia('(max-width: 768px)').matches) {
      cylinder2.rotation.z = 0.3;
      cylinder2.rotation.x = 0.3;
    } else {
    }

    scene.add(wrap);

    const loaderSVG = new SVGLoader();
    const loaderSVG2 = new SVGLoader();
    const groupPath = new THREE.Group();
    const groupPath2 = new THREE.Group();
    const SVGmaterial = new THREE.MeshBasicMaterial({
      color: 0x00ff50,
      transparent: true,
      side: THREE.DoubleSide,
      depthWrite: false,
    });
    const SVGmaterial2 = new THREE.MeshBasicMaterial({
      color: 0x00ff50,
      transparent: true,
      side: THREE.DoubleSide,
      depthWrite: false,
    });
    loaderSVG.load('/assets/images/kv_cr.svg', function (data) {
      const paths = data.paths;
      for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        for (let j = 0, jl = path.subPaths.length; j < jl; j++) {
          const subPath = path.subPaths[j];
          const geometry = SVGLoader.pointsToStroke(subPath.getPoints(), path.userData.style);
          if (geometry) {
            const mesh = new THREE.Mesh(geometry, SVGmaterial);
            groupPath.add(mesh);
          }
        }
      }
      groupPath.position.z = 0;
      groupPath.position.y = 17;
      groupPath.position.x = -60;
      groupPath.scale.x = 0.25;
      groupPath.scale.y = -0.25;
      groupPath.rotation.x = 0;
      SVGmaterial.opacity = 0;
      scene.add(groupPath);
      let event = new Event('TextureLoaded', { bubbles: true });
      document.dispatchEvent(event);
    });

    loaderSVG2.load('/assets/images/kv_cm.svg', function (data) {
      const paths = data.paths;
      for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        for (let j = 0, jl = path.subPaths.length; j < jl; j++) {
          const subPath = path.subPaths[j];
          const geometry = SVGLoader.pointsToStroke(subPath.getPoints(), path.userData.style);
          if (geometry) {
            const mesh = new THREE.Mesh(geometry, SVGmaterial2);
            groupPath2.add(mesh);
          }
        }
      }
      groupPath2.position.z = 0;
      groupPath2.position.y = -5; //17; //7; //17;
      groupPath2.position.x = -84;
      groupPath2.scale.x = 0.25;
      groupPath2.scale.y = -0.25; // -0.25;
      // groupPath2.rotation.x = Math.PI * 10;
      SVGmaterial2.opacity = 0;

      scene.add(groupPath2);
      let event = new Event('TextureLoaded', { bubbles: true });
      document.dispatchEvent(event);
    });

    const AmbientLight = new THREE.AmbientLight(0xffffff, 1.0);
    scene.add(AmbientLight);

    window.addEventListener('resize', onWindowResize);

    function onWindowResize() {
      let ua = navigator.userAgent;
      if ((ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0) && ua.indexOf('Mobile') > 0) {
        return false;
      }
      let per = 540;
      let width = window.innerWidth;
      const height = window.innerHeight;
      renderer.setPixelRatio(window.devicePixelRatio);

      if (width > 1440) {
        width = 1440;
      }
      if (width < 786) {
        per = 380;
      }
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();

      let cameraPosZ = (1440 / width) * per;
      camera.position.set(0, 0, cameraPosZ);
    }

    let composerBool = false;
    let whichWay = true;
    onWindowResize();
    tick();

    function tick() {
      TWEEN.update();
      render();
      requestAnimationFrame(tick);
    }

    function render() {
      cylinder.rotation.y -= 0.002;
      cylinder2.rotation.y -= 0.002;
      if (window.matchMedia('(max-width: 768px)').matches) {
      } else {
        wrap.rotation.z = cylinderZ;
        wrap.rotation.x = cylinderX;
      }
      renderer.render(scene, camera);
    }
    document.body.addEventListener('mousemove', function (e) {
      cylinderZ = ((e.clientX / window.innerWidth) * 2 - 1) * 0.5;
      cylinderX = (-(e.clientY / window.innerHeight) * 2 + 1) * 0.5;
    });

    this.Timer = setTimeout(
      () => {
        new TWEEN.Tween(SVGmaterial)
          .to({ opacity: 1 }, 2000)
          .easing(TWEEN.Easing.Sinusoidal.InOut)
          .start();
        setTimeout(() => {
          CrMotion();
        }, 3000);
      },
      this.TimerBool !== null ? 1000 : 7000
    );

    function CrMotion() {
      new TWEEN.Tween(SVGmaterial)
        .to({ opacity: 0 }, 800)
        .easing(TWEEN.Easing.Sinusoidal.InOut)
        .start();
      new TWEEN.Tween(groupPath.position)
        .to({ y: 0 }, 1000)
        .easing(TWEEN.Easing.Sinusoidal.InOut)
        .start();

      new TWEEN.Tween(SVGmaterial2)
        .to({ opacity: 1 }, 1200)
        .easing(TWEEN.Easing.Sinusoidal.InOut)
        .onComplete(() => {
          CMotionTimer();
        })
        .start();
      new TWEEN.Tween(groupPath2.position)
        .to({ y: 17 }, 1000)
        .easing(TWEEN.Easing.Cubic.InOut)
        .start();
    }

    function CoMotion() {
      new TWEEN.Tween(SVGmaterial2)
        .to({ opacity: 0 }, 800)
        .easing(TWEEN.Easing.Sinusoidal.InOut)
        .start();
      new TWEEN.Tween(groupPath2.position)
        .to({ y: 0 }, 1000)
        .easing(TWEEN.Easing.Sinusoidal.InOut)
        .start();

      new TWEEN.Tween(SVGmaterial)
        .to({ opacity: 1 }, 1200)
        .easing(TWEEN.Easing.Sinusoidal.InOut)
        .onComplete(() => {
          CMotionTimer();
        })
        .start();
      new TWEEN.Tween(groupPath.position)
        .to({ y: 17 }, 1000)
        .easing(TWEEN.Easing.Cubic.InOut)
        .start();
    }

    function CMotionTimer() {
      setTimeout(() => {
        if (whichWay) {
          CoMotion();
        } else {
          CrMotion();
        }
        whichWay = !whichWay;
      }, 4000);
    }

    window.addEventListener('scroll', function () {
      ScrollEv();
    });

    function ScrollEv() {
      if (window.innerHeight < window.pageYOffset) {
        Box.style.opacity = 0;
        Box.style.visibility = 'hidden';
        Box.style.display = 'none';
        return false;
      }
      let Val = (window.pageYOffset / window.innerHeight) * 2.5;
      Box.style.opacity = Number(1 - Val);
      Box.style.transform = 'scale(' + Number(1 + Val / 2) + ')';
      Box.style.visibility = 'visible';
      Box.style.display = 'block';
    }

    ScrollEv();
  }
}
