import axios from 'axios';

export default class FormEvent {
  compName = document.querySelector('.js-f-compName');
  email = document.querySelector('.js-f-compEmail');
  cat = document.querySelector('.js-f-compCat');
  msg = document.querySelector('.js-f-compMsg');
  checkBtn = document.querySelector('.js-f-checkButton');

  confBack = document.querySelector('.js-formConfBack');
  confSend = document.querySelector('.js-formConfSend');
  confBody = document.querySelector('.topContactFromConfBody');
  confFin = document.querySelector('.topContactFromFinBody');
  formClose = document.querySelector('.js-formClose');
  conf = document.querySelector('.js-formConf');

  confComp = document.querySelector('.js-formConfComp');
  confMail = document.querySelector('.js-formConfMail');
  confCat = document.querySelector('.js-formConfCat');
  confMsg = document.querySelector('.js-formConfMsg');

  constructor() {
    this.init();
  }
  init() {
    const requiredElems = document.querySelectorAll('.required');
    if (requiredElems.length < 1) {
      return false;
    }
    this.compName.addEventListener('blur', () => {
      this.checkElms(requiredElems);
    });
    this.email.addEventListener('blur', () => {
      this.checkElms(requiredElems);
    });
    this.cat.addEventListener('change', () => {
      this.checkElms(requiredElems);
    });
    this.msg.addEventListener('blur', () => {
      this.checkElms(requiredElems);
    });

    this.checkBtn.addEventListener('click', () => {
      this.confView();
    });

    this.confBack.addEventListener('click', () => {
      this.conf.classList.add('Fade');

      setTimeout(() => {
        this.conf.classList.add('Hide');
        this.conf.classList.remove('Act');
        this.conf.classList.remove('Fade');
      }, 500);
    });

    this.confSend.addEventListener('click', () => {
      this.submitEvent();
    });

    this.formClose.addEventListener('click', () => {
      this.conf.classList.add('Fade');
      this.confComp.value = '';
      this.compName.value = '';
      this.confMail.value = '';
      this.email.value = '';
      this.confCat.value = '';
      this.cat.value = '';
      this.confMsg.value = '';
      this.msg.value = '';
      setTimeout(() => {
        this.conf.classList.add('Hide');
        this.conf.classList.remove('Act');
        this.conf.classList.remove('Fade');
        this.confBody.classList.remove('Hide');
        this.confFin.classList.add('Hide');
      }, 500);
    });
  }

  async submitEvent() {
    const result = await this.sendEvent();
    // console.log(result);
    this.confBody.classList.add('Hide');
    this.confFin.classList.remove('Hide');
  }

  async sendEvent() {
    const url = '/sendmail.php';
    const params = {
      company: this.confComp.value,
      email: this.confMail.value,
      category: this.confCat.value,
      msg: this.confMsg.value,
    };
    console.log(params);

    // const url = 'https://official-joke-api.appspot.com/jokes/random';
    // return await axios
    //   .get(url)
    //   .then(function (res) {
    //     return res.data;
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    return await axios
      .post(url, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(function (res) {
        return res.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  confView() {
    this.conf.classList.remove('Hide');
    this.conf.classList.add('Act');
    this.confComp.value = this.compName.value;
    this.confMail.value = this.email.value;
    this.confCat.value = this.cat.value;
    this.confMsg.value = this.msg.value;
  }

  checkElms(requiredElems) {
    let checkBool = true;
    requiredElems.forEach((elem) => {
      const elemValue = elem.value.trim();
      // console.log(elem.value);
      let pattern;
      let errorMessage = '入力された形式が正しくないようです。';
      let dataPattern = elem.getAttribute('data-pattern');
      if (dataPattern) {
        switch (dataPattern) {
          case 'email':
            pattern = /^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/iu;
            errorMessage = 'メールアドレスの形式が正しくありません。';
            break;
          default:
            pattern = new RegExp(dataPattern);
        }
      }
      if (elemValue.length === 0) {
        checkBool = false;
      } else {
        if (dataPattern) {
          if (!pattern.test(elem.value)) {
            alert(errorMessage);
            checkBool = false;
          }
        }
      }
    });
    console.log(checkBool);
    if (checkBool) {
      this.checkBtn.classList.remove('Disabled');
    } else {
      this.checkBtn.classList.add('Disabled');
    }
  }
}
