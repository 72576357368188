'use strict';
import './structure/adjustVh';
import './structure/adjustViewport';
import InViewEvent from './components/inViewEvent';
import NavEvent from './components/NavEvent';
import ThreeEvent from './components/threeEvent';
import ScrollMagicEvent from './components/scrollMagicEvent';
import ScrollMemberEvent from './components/scrollMemberEvent';
import StalkerEvent from './components/stalkerEvent';
import SmoothScroll from 'smooth-scroll';
import lottie from 'lottie-web';
import { lock, unlock } from 'tua-body-scroll-lock';
import FormEvent from './components/formEvent';

document.body.addEventListener(
  'touchmove',
  (e) => {
    if (e.touches.length > 1) {
      e.preventDefault();
    }
  },
  { passive: false }
);

document.addEventListener('DOMContentLoaded', () => {
  // sessionStorage.removeItem('movie');

  const stalker = new StalkerEvent();
  const navEvent = new NavEvent();
  const inViewEevnt = new InViewEvent();

  let options = {
    speed: 50,
    easing: 'easeInOutCubic',
    updateURL: false,
  };
  const scroll = new SmoothScroll('a[href*="#"]', options);
  //
  if (!document.querySelector('.topPage')) {
    return false;
  }
  window.scrollTo(0, 0);
  lock();

  const movieBool = sessionStorage.getItem('movie');
  const KVCanvas = document.getElementById('KV');
  const kvThree = new ThreeEvent(KVCanvas, movieBool);
  const magic = new ScrollMagicEvent();
  const member = new ScrollMemberEvent();

  const formEvent = new FormEvent();

  const Loader = document.querySelector('.l_loader');
  const KV = document.querySelector('.topKV');
  let Count = 0;

  document.addEventListener('TextureLoaded', function (event) {
    Count++;
    if (Count == 3) {
      Loader.classList.add('Hide');
      setTimeout(() => {
        Loader.classList.add('None');
      }, 500);
      if (movieBool !== null) {
        fvMovieWrap.classList.add('None');
        fvMovieWrap.classList.add('Hide');
        unlock();
        KV.classList.add('View');
      } else {
        Opening();
        sessionStorage.setItem('movie', 'Done');
      }
      document.querySelector('.p_headerWrap').classList.add('View');
    }
    // console.log(event);
  });

  const fvMovieWrap = document.querySelector('.l_fvMovie');
  const fvMovie = document.getElementById('fvMovie');
  const fvMovieSP = document.getElementById('fvMovieSP');
  let LoadedCount = 0;
  const Opening = () => {
    if (movieBool !== null) {
      return false;
    }
    const animationTivel = lottie.loadAnimation({
      container: fvMovie,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/assets/images/fv_movie.json',
    });
    const animationTivelSP = lottie.loadAnimation({
      container: fvMovieSP,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/assets/images/fv_movie_sp.json',
    });

    animationTivel.addEventListener('data_ready', () => {
      // console.log('Loaded');
      LoadedCount++;
      if (LoadedCount > 1) {
        animationTivel.play();
        animationTivelSP.play();
      }
    });
    animationTivelSP.addEventListener('data_ready', () => {
      // console.log('Loaded');
      LoadedCount++;
      if (LoadedCount > 1) {
        animationTivel.play();
        animationTivelSP.play();
      }
    });
    animationTivel.onComplete = (e) => {
      // console.log('onComplete');
      fvMovieWrap.classList.add('Hide');
      setTimeout(() => {
        fvMovieWrap.classList.add('None');
        unlock();
      }, 500);
      setTimeout(() => {
        KV.classList.add('View');
      }, 500);
    };
  };

  if (movieBool === null) {
    const setAspectMV = () => {
      if (window.innerWidth / window.innerHeight > 1.77) {
        fvMovie.classList.add('WFit');
        fvMovie.classList.remove('HFit');
        fvMovieSP.classList.add('WFit');
        fvMovieSP.classList.remove('HFit');
      } else {
        fvMovie.classList.add('HFit');
        fvMovie.classList.remove('WFit');
        fvMovieSP.classList.add('HFit');
        fvMovieSP.classList.remove('WFit');
      }
    };
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    handle(mediaQuery);
    function handle(mm) {
      if (mm.matches) {
        fvMovie.style.display = 'none';
        fvMovieSP.style.display = 'block';
      } else {
        fvMovie.style.display = 'block';
        fvMovieSP.style.display = 'none';
      }
    }
    window.addEventListener('resize', () => {
      handle(mediaQuery);
      let ua = navigator.userAgent;
      if ((ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0) && ua.indexOf('Mobile') > 0) {
        return false;
      }
      setAspectMV();
    });
    setAspectMV();
  }
});
