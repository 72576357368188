export default class StalkerEvent {
  constructor() {
    this.init();
  }
  init() {
    if (!document.getElementById('stalker')) {
      return false;
    }
    // マウスストーカー要素
    let mouseStalker;
    let mouseStalkerOuter;
    // マウスストーカー要素の位置
    let stalker = {
      x: 0,
      y: 0,
    };
    // マウスの位置
    let mouse = {
      x: 0,
      y: 0,
    };

    // 読み込み後に初期化をするためのハンドラ
    // document.addEventListener('DOMContentLoaded', setup);

    // マウスの動きを監視
    document.addEventListener('mousemove', mousemove);
    // setup();
    // 初期化処理
    // function setup() {
    // マウスストーカー要素を取得
    mouseStalker = document.getElementById('stalker');
    mouseStalkerOuter = document.getElementById('stalkerOuter');

    // 更新処理を開始

    // const StalkerBases = document.querySelectorAll('.js-StalkerBase');
    // const StalkerBasesPrev = document.querySelector('.js-StalkerBasePrev');
    // const StalkerBasesNext = document.querySelector('.js-StalkerBaseNext');
    // for (let i = 0; i < StalkerBases.length; i++) {
    //   StalkerBases[i].addEventListener('mouseover', function () {
    //     mouseStalker.classList.add('View');
    //   });
    //   StalkerBases[i].addEventListener('mouseout', function () {
    //     mouseStalker.classList.remove('View');
    //   });
    // }
    // if (StalkerBasesPrev) {
    //   StalkerBasesPrev.addEventListener('mouseover', function () {
    //     mouseStalker.firstChild.classList.add('ArrowL');
    //   });
    //   StalkerBasesPrev.addEventListener('mouseout', function () {
    //     mouseStalker.firstChild.classList.remove('ArrowL');
    //   });

    //   StalkerBasesNext.addEventListener('mouseover', function () {
    //     mouseStalker.firstChild.classList.add('ArrowR');
    //   });
    //   StalkerBasesNext.addEventListener('mouseout', function () {
    //     mouseStalker.firstChild.classList.remove('ArrowR');
    //   });
    // }

    update();
    // }

    // マウスが動くたびにマウスの位置を保持しておく
    function mousemove(e) {
      mouse.x = e.clientX;
      mouse.y = e.clientY;
    }

    // 更新処理
    function update() {
      // マウスストーカー要素の位置を更新
      stalker.x += (mouse.x - stalker.x) * 0.6;
      stalker.y += (mouse.y - stalker.y) * 0.4;
      // マウスストーカーの位置を小数点第一位まで四捨五入
      let x = Math.round(stalker.x * 10) / 10;
      let y = Math.round(stalker.y * 10) / 10;
      // マウスストーカー要素のスタイルを更新
      mouseStalker.style.transform = `translate3d(` + x + 'px,' + y + 'px, 0)';
      mouseStalkerOuter.style.transform = `translate3d(` + x + 'px,' + y + 'px, 0)';
      // ループ
      requestAnimationFrame(update);
    }
  }
}
