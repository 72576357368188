import ScrollMagic from 'scrollmagic';

export default class ScrollMagicEvent {
  constructor() {
    this.init();
  }
  init() {
    let controller = new ScrollMagic.Controller();
    const wrap = document.querySelector('.topBusinessInner');
    const Memory = document.querySelector('.topBusinessLineMemory');
    let prog = 0;
    let PreProg = 0;
    const initScene = () => {
      const s = new ScrollMagic.Scene({
        triggerElement: '#pinBusiness',
        triggerHook: 0,
        duration: '800%',
      })
        .setPin('#pinBusiness')
        .on('enter', function (e) {
          wrap.classList.add('View');
        })
        .on('progress', function (e) {
          prog = e.progress;
          // console.log(prog);
          // Memory.style.top = prog * 100 + '%';
          ScrollMember();
        })
        .on('leave', function (e) {
          wrap.classList.remove('View');
        })
        .addTo(controller);
    };

    let box = document.querySelector('.topBusinessBodyInner');
    let boolArr = [true, true, true, true, true];
    const titleLogo1 = document.querySelector('.js-titleLogo1');
    const titleLogo2 = document.querySelector('.js-titleLogo2');
    const line = document.querySelector('.topBusinessLine');
    const ScrollMember = () => {
      if (prog < 0.15) {
        if (!boolArr[0]) {
          return false;
        }
        boolArr[0] = false;
        boolArr[1] = true;
        boolArr[2] = true;
        boolArr[3] = true;
        boolArr[4] = true;
        box.style.transform = 'translateY(0vh)';
        Memory.style.top = 0;
      }
      if (prog >= 0.15 && prog < 0.3) {
        if (!boolArr[1]) {
          return false;
        }
        boolArr[1] = false;
        boolArr[0] = true;
        boolArr[2] = true;
        boolArr[3] = true;
        boolArr[4] = true;
        box.style.transform = 'translateY(-100vh)';
        Memory.style.top = '20%';
      }
      if (prog >= 0.3 && prog < 0.55) {
        if (!boolArr[2]) {
          return false;
        }
        boolArr[2] = false;
        boolArr[0] = true;
        boolArr[1] = true;
        boolArr[3] = true;
        boolArr[4] = true;
        box.style.transform = 'translateY(-200vh)';
        Memory.style.top = '40%';
      }
      if (prog >= 0.55 && prog < 0.7) {
        if (!boolArr[3]) {
          return false;
        }
        boolArr[3] = false;
        boolArr[0] = true;
        boolArr[1] = true;
        boolArr[2] = true;
        boolArr[4] = true;
        box.style.transform = 'translateY(-300vh)';
        titleLogo2.classList.remove('View');
        titleLogo1.classList.add('View');
        line.classList.remove('Community');
        Memory.style.top = '60%';
      }
      if (prog >= 0.7 && prog < 1) {
        if (!boolArr[4]) {
          return false;
        }
        boolArr[4] = false;
        boolArr[0] = true;
        boolArr[1] = true;
        boolArr[2] = true;
        boolArr[3] = true;
        box.style.transform = 'translateY(-400vh)';
        titleLogo1.classList.remove('View');
        titleLogo2.classList.add('View');
        line.classList.add('Community');
        Memory.style.top = '80%';
      }
      PreProg = prog;
    };

    if (window.matchMedia('(max-width: 768px)').matches) {
    } else {
      initScene();
    }
    window.addEventListener('resize', () => {
      if (controller) controller = controller.destroy(true);
      if (window.matchMedia('(max-width: 768px)').matches) {
        box.style.transform = 'translateY(0)';
      } else {
        controller = new ScrollMagic.Controller();
        initScene();
      }
    });
  }
}
