export default class MemberEvent {
  constructor(card) {
    this.card = card;
    this.init();
  }

  init() {
    const card = this.card;
    const cardImag = card.querySelector('.m_memberImage');
    const carBox = document.querySelector('.topMemberBox');
    const carBoxWrap = document.querySelector('.topMemberBoxInnerWrap');

    carBox.addEventListener('mousemove', (e) => {
      if (window.matchMedia('(max-width: 768px)').matches) {
        return false;
      }
      const current = e.currentTarget;
      const target_rect = current.getBoundingClientRect();
      const x = e.clientX - target_rect.left;
      const y = e.clientY - target_rect.top;
      // console.log('(x, y) = (' + x + ',' + y + ')');
      const xx = current.clientWidth - x * 2;
      const yy = current.clientHeight - y * 2;
      const rx = -xx / 35;
      const ry = yy / 75;
      cardImag.style.transform =
        'scaleX(1) scaleY(1) scaleZ(1) translateX(0px) translateY(0px) translateZ(0px) rotateY(' +
        rx +
        'deg) rotateX(' +
        ry +
        'deg) rotateZ(0deg)';
      // carBoxWrap.style.transform =
      //   'scaleX(1) scaleY(1) scaleZ(1) translateX(0px) translateY(0px) translateZ(0px) rotateY(' +
      //   rx * 1.5 +
      //   'deg) rotateX(' +
      //   ry * 1.5 +
      //   'deg) rotateZ(0deg)';
    });
    // carBox.addEventListener('mouseenter', (e) => {
    //   if (window.matchMedia('(max-width: 768px)').matches) {
    //     return false;
    //   }
    //   const current = e.currentTarget;
    //   // cardImag.classList.add('enter');
    //   // cardImag.classList.remove('leave');
    //   setTimeout(function () {
    //     cardImag.classList.remove('enter');
    //   }, 100);
    // });
    carBox.addEventListener('mouseleave', (e) => {
      if (window.matchMedia('(max-width: 768px)').matches) {
        return false;
      }
      // const current = e.currentTarget;
      cardImag.style.transform = 'rotate(0)';
      // carBoxWrap.style.transform = 'rotate(0)';
      // cardImag.classList.add('leave');
    });
  }
}
