// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { lock, unlock } from 'tua-body-scroll-lock';

export default class NavEvent {
  constructor() {
    this.init();
  }
  init() {
    const toggles = document.querySelectorAll('.js-NavToggle');
    const Nav = document.getElementById('js-NavContent');
    const smoothNav = document.querySelectorAll('.js-Smooth');
    for (let i = 0; i < toggles.length; i++) {
      toggles[i].addEventListener('click', (e) => {
        if (Nav.classList.contains('Act')) {
          unlock();
        } else {
          lock();
        }

        Nav.classList.toggle('Act');
      });
    }

    for (let k = 0; k < smoothNav.length; k++) {
      smoothNav[k].addEventListener('click', (e) => {
        // console.log(e.currentTarget.href);
        const href = e.currentTarget.href;

        setTimeout(() => {
          Nav.classList.remove('Act');
          unlock();
        }, 400);

        setTimeout(() => {
          if (href.indexOf('#member') != -1) {
            document.querySelector('.topMemberInner').classList.add('View');
          }
        }, 800);
      });
    }

    const hovers = document.querySelectorAll('.js-hover');
    const stalker = document.getElementById('stalker');
    for (let j = 0; j < hovers.length; j++) {
      hovers[j].addEventListener('mouseover', (e) => {
        stalker.classList.add('Hover');
      });
      hovers[j].addEventListener('mouseout', (e) => {
        stalker.classList.remove('Hover');
      });
    }
  }
}
