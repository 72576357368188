import MemberEvent from './memberEvent';
import ScrollMagic from 'scrollmagic';
import lottie from 'lottie-web';

export default class ScrollMemberEvent {
  constructor() {
    this.init();
  }
  init() {
    let controller = new ScrollMagic.Controller();
    let prog = 0;
    let PreProg = 0;
    const membersLeng = document.querySelectorAll('.c_memberOne').length;
    const memberWrap = document.querySelector('.topMemberInner');
    const box = document.querySelector('.topMemberBoxInner');
    const ticker = document.querySelector('.topMemberTickerInner');
    const Image = document.querySelector(' .m_memberImageWrap');
    const tickers = document.querySelectorAll('.topMemberTickerOne');
    const initScene = () => {
      let duration = membersLeng * 120 + '%';
      const s = new ScrollMagic.Scene({
        triggerElement: '#pinMember',
        triggerHook: 0,
        duration: duration,
      })
        .setPin('#pinMember')
        .on('enter', function (e) {
          // console.log('E');
          memberWrap.classList.add('View');
        })
        .on('progress', function (e) {
          prog = e.progress;
          ScrollMember();
        })
        .on('leave', function (e) {
          // console.log('L');
          memberWrap.classList.remove('View');
        })
        .addTo(controller);
    };

    const oneSpan = 0.8 / membersLeng;
    const oneSpanVal = 1 / membersLeng;
    let currentCardIndex = 0;
    const perArr = [];
    const perArrVal = [];
    for (let i = 1; i <= membersLeng; i++) {
      perArr.push(oneSpan * i);
    }
    for (let i = 0; i < membersLeng; i++) {
      perArrVal.push(oneSpanVal * i * 100);
    }
    const ScrollMember = () => {
      if (prog > PreProg) {
        if (prog < perArr[currentCardIndex]) {
        } else {
          currentCardIndex++;
          if (currentCardIndex >= membersLeng) {
            currentCardIndex = membersLeng;
            PreProg = prog;
            return false;
          }
          if (currentCardIndex < 0) {
            currentCardIndex = 0;
            return false;
          }
          box.style.transform = 'translateY(-' + perArrVal[currentCardIndex] + '%)';
          ticker.style.transform = 'translateY(-' + perArrVal[currentCardIndex] + '%)';
          Image.style.transform = 'translateY(-' + perArrVal[currentCardIndex] + '%)';

          for (let i = 0; i < tickers.length; i++) {
            if (currentCardIndex === i) {
              tickers[i].classList.add('Act');
            } else {
              tickers[i].classList.remove('Act');
            }
          }
        }
      } else {
        if (prog > perArr[currentCardIndex]) {
        } else {
          if (perArr[currentCardIndex] === undefined) {
            currentCardIndex--;
            PreProg = prog;
            return false;
          }
          if (currentCardIndex < 0) {
            currentCardIndex = 0;
            PreProg = prog;
            return false;
          }
          box.style.transform = 'translateY(-' + perArrVal[currentCardIndex] + '%)';
          ticker.style.transform = 'translateY(-' + perArrVal[currentCardIndex] + '%)';
          Image.style.transform = 'translateY(-' + perArrVal[currentCardIndex] + '%)';

          for (let i = 0; i < tickers.length; i++) {
            if (currentCardIndex === i) {
              tickers[i].classList.add('Act');
            } else {
              tickers[i].classList.remove('Act');
            }
          }
          currentCardIndex--;
        }
      }
      PreProg = prog;
    };

    const members = document.querySelector('.js-memberCard');
    new MemberEvent(members);

    const hearts = document.querySelectorAll('.HeartInner');
    for (let i = 0; i < hearts.length; i++) {
      const animationTivel = lottie.loadAnimation({
        container: hearts[i],
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/assets/images/heart.json',
      });
      hearts[i].addEventListener('click', () => {
        animationTivel.goToAndPlay(20);
      });
    }

    initScene();

    window.addEventListener('resize', () => {
      let ua = navigator.userAgent;
      if ((ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0) && ua.indexOf('Mobile') > 0) {
        return false;
      }
      controller = controller.destroy(true);
      controller = new ScrollMagic.Controller();
      initScene();
    });
  }
}
